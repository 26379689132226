<template>
  <div class="page" v-loading='loading' element-loading-text="正在排查">
    <div class="page-title">
      <div class="title-name">发文排查</div>
      <div class="title-btn">
        <div class="yes btn " @click="checkContent" >开始排查</div>
        <el-button type="success" style="margin-left: 0.625rem" @click="removeContent">清空</el-button>
      </div>
    </div>
    <div class="content-card">
      <div class="content-title">
        <Title class="content-title-left">排查分析内容</Title>
        <Title class="content-title-right">排查内容错误列表</Title>
      </div>
      <div class="content-main">
        <div class="content-main-left">
          <!-- <el-input v-if="is_show_text" v-model="content" type="textarea" :rows="30" placeholder="请输入内容">
          </el-input> -->
          <div class="divcont" ref="content" v-html="redContent" contenteditable="true"></div>
        </div>
        <div class="content-main-right">
          <el-card shadow="hover" v-for="(item,index) in errList" :key="index">
            <el-link type="danger" :underline="false">{{item.error}}</el-link>
            <span>--></span>
            <el-link type="success" @click="editErr(item)" :disabled="item.is_click">{{item.correction}}</el-link>
            <!-- @click="editErr(item)" -->
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "../settings/components/Title.vue";
import {
  checkContent,//排查内容
} from "../../api/troubleshoot/index"; 
export default {
  components: {
    Title,
  },
  data() {
    return {
      content: '',//内容
      redContent: '',//标红内容
      special_misre: 1,//错误表述
      ordinary_misre: 1,//错别字
      loading: false,//排查中
      errList: [],//错误列表
      is_show_text: true,
    }
  },
  methods: {
    // 清空
    removeContent() {
      this.errList =[] // 错误列表
      if(!this.$refs.content.innerText) 
      return this.$message.success('内容空空如也')
      this.$refs.content.innerText = "" // 内容
   
    },  
    // 开始排查
    async checkContent() {
       this.redContent = this.$refs.content.innerText
      const { special_misre, ordinary_misre } = this
      let data = {
        content: this.$refs.content.innerText,
        special_misre,
        ordinary_misre,
      }
      if (data.content == '') {
        this.$message.warning('请输入内容')
        return
      }
      this.loading = true
      try {
        let res = await checkContent(data)
        if(res?.data?.length < 1) {
            this.loading = false
            this.errList= []
           this.$message.warning('当前文章没有错误词！')
           return
          }
          //   this.errList = res.data || []
          //    this.replaceArr(this.errList)
          //       this.loading = false
          // this.is_show_text = false
          // return
                if (res) {
        let sortedArr = res.data.sort((a, b) => {
          let positionA = a.position;
          let positionB = b.position;
          // 比较 positionA 和 positionB 的第一个元素
          let compareResult = positionB[0] - positionA[0];
          // 如果第一个元素相等，则比较第二个元素
          if (compareResult === 0) {
            compareResult = positionB[1] - positionA[1];
          }
          return compareResult;
        });
        let arr = JSON.parse(JSON.stringify(sortedArr));
        sortedArr.forEach((x) => {
          arr.forEach((n, i) => {
            //剔除包含关系
            if (x.position[0] <= n.position[0] && x.position[1] > n.position[1]) {
              arr.splice(i, 1);
            }

          })
        });
        //重新定义重叠关系 [30, 37] [28, 32]  
        let arr2 = [];
        if (arr.length == 1) {
          arr2.push({
            "correction": [arr[0].correction],
            "error": [arr[0].error],
            "position": arr[0].position,
            "tips": [arr[0].tips]
          })
        } else {
          for (let i = 0; i <= arr.length - 1; i++) {
            if (i == arr.length - 1) {
              arr2.push({
                "correction": [arr[i].correction],
                "error": [arr[i].error],
                "position": arr[i].position,
                "tips": [arr[i].tips]
              });
              break;
            }
            if (arr[i].position[0] >= arr[i + 1].position[0] && arr[i].position[0] <= arr[i + 1].position[1]) {
              arr2.push({
                "correction": [arr[i].correction, arr[i + 1].correction],
                "error": [arr[i].error, arr[i + 1].error],
                "position": [
                  arr[i + 1].position[0],
                  arr[i].position[1]
                ],
                "tips": [arr[i].tips, arr[i + 1].tips]
              });
              i++;
            } else {
              arr2.push({
                "correction": [arr[i].correction],
                "error": [arr[i].error],
                "position": arr[i].position,
                "tips": [arr[i].tips]
              });
            }
          }
        }
        arr2.forEach((e, i) => {
           let str = this.redContent
             this.redContent = str.substring(0, e.position[0]) + "<span style='color: red;font-size:130%;font-weight:800'>" +
          str.substring(e.position[0], e.position[1]) + "</span>" +
          str.substring(e.position[1]);
        })
        this.errList = arr;
        // this.ErrorList2 = arr2;
      }
            this.loading = false
          this.is_show_text = false
        return
        if (res.data.code == 200) {
          this.loading = false
          this.is_show_text = false
          console.log(res.data);
          this.errList = [...res.data.result.special_misre_all.content_misre, ...res.data.result.ordinary_misre_all.content_misre]
          // this.content = res.data.result.content
          if (this.errList.length == 0) {
            this.$message.success('排查完毕,该内容暂无错误,排查结果仅供参考!')
            return
          }
          this.errList.forEach(i => i.is_click = false)
          // this.redContent = this.$refs.content.innerText//获取文本,为下面处理高亮做准备

          this.replaceArr(this.errList)
        }
      } catch (error) {
        this.$message.warning(error)
        this.loading = false
      }
    },

    replaceArr(errList) {
      this.redContent = this.$refs.content.innerText
      //数组组合之后重新排序,从末位到开头添加span标签,这样不会打乱下标位置,修改的时候依旧用正则去替换
      let sortedArr = [...errList].sort((a, b) => {
        let positionA = a.position;
        let positionB = b.position;
        // 比较 positionA 和 positionB 的第一个元素
        let compareResult = positionB[0] - positionA[0];
        // 如果第一个元素相等，则比较第二个元素
        if (compareResult === 0) {
          compareResult = positionB[1] - positionA[1];
        }
        return compareResult;
      });
      sortedArr.forEach(e => {
        let str = this.redContent
        this.redContent = str.substring(0, e.position[0]) + "<span style='color: red'>" +
          str.substring(e.position[0], e.position[1]) + "</span>" +
          str.substring(e.position[1]);
      });
    },
    // 点击修改错误
    editErr(item) {
      let htmlContent = this.$refs.content.innerHTML
      console.log(item);
      // return
      item.is_click = true
      // 上面处理了高亮字符串,所以需要修改的字符串都被span标签包裹了,替换这个span标签我没有实现,所以用 >< 包裹目标字符串
      let oRegExp = new RegExp(`>${item.error}<`, "gi");
      this.redContent = htmlContent.replace(
        oRegExp,
        `><span style="color: green;">${item.correction}</span><`
      );
    },
  },
  mounted() {
    // console.log(chrome) 
  }
}
</script>

<style lang="scss" scoped>
.page {
  .page-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 0 24px 0;
    .title-name {
      font-size: 26px;
      font-weight: 600;
      color: #1a1a1a;
    }
    .title-btn {
      display: flex;
      justify-content: flex-end;
      .btn {
        padding: 7px 24px;
        margin-left: 16px;
        font-size: 16px;
        border-radius: 4px;
      }
      .no {
        background: #fff1e8;
        color: #ed731f;
      }
      .yes {
        background: #f58030;
        color: #ffffff;
      }
      .testbtn {
        width: 50px;
        height: 20px;
      }
    }
  }
  .content-card {
    width: 100%;
    // height: 819px;
    padding: 24px;
    padding-top: 0;
    .content-title {
      display: flex;
      justify-content: space-between;
      .content-title-left {
        width: 75%;
      }
      .content-title-right {
        width: 24%;
      }
    }
    .content-main {
      display: flex;
      justify-content: space-between;
      padding-top: 24px;
      .content-main-left {
        width: 75%;
        height: 670px;

        background: #ffffff;
        border-radius: 4px;
        padding: 16px;
        ::v-deep .el-textarea__inner {
          border: 0px solid red;
        }
        .divcont {
          width: 100%;
          height: 100%;
          overflow: auto;
        }
      }
      .content-main-right {
        width: 24%;
        height: 670px;
        overflow: auto;
        background: #ffffff;
        padding: 16px;
        border-radius: 4px;
        // margin-left: 20px;
        .el-card {
          margin-bottom: 16px;
        }
        .el-link {
          margin: 0 24px;
        }
      }
    }
  }
}
[contenteditable] {
  outline: 0px solid transparent; //溢出可编辑dom元素的边框
}
.red{
  color: red;
}
.green{
  color: green;
}
</style>

